import React, { useEffect } from 'react';

import { locationInformation, userInformation, searchData } from '../atoms/atoms';
import { useRecoilState } from 'recoil';
import { window } from 'browser-monads';
import { GetGeoLocation } from './utils';
import { graphql, useStaticQuery } from 'gatsby';


function StateManager() {
    const [loc, setLoc] = useRecoilState(locationInformation);
    const [uInfo, setUInfo] = useRecoilState(userInformation);
    const [sData, setSData] = useRecoilState(searchData);

    const data = useStaticQuery(graphql`
      query {
        comingSoon: file(relativePath: {eq: "comingSoon.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        site {
          buildTime(formatString: "YYYY-MM-DD hh:mm a z")
          siteMetadata {
            apiURL
          }
        }
      }
    `)

  // console.log(data.site.siteMetadata.apiURL)


  localStorage.setItem("uInfo", JSON.stringify(uInfo));
  sessionStorage.setItem("sData", JSON.stringify(sData));


  function getSetLocationState() {
    console.info("Performing a location check...");


    if (localStorage.getItem("location") !== undefined) {
      localStorage.setItem("location", JSON.stringify(loc));
    } else {
      console.log("Not performing a location check -- check already performed...")
      return
    } 

    try {
      var location = JSON.parse(localStorage.getItem("location"));

      if (location.origin_postalCode && location.origin_postalCode != "") {
        setLoc(location);
        localStorage.setItem("location", JSON.stringify(location));
      } else {
        console.info("No previous information is available...");

        GetGeoLocation(data.site.siteMetadata.apiURL).then(data => {
          // Normalizing the zip code in case it has dashes as part of a multipart
          // zip code.
          data.origin_postalCode = data.origin_postalCode.split('-')[0];
          setLoc({...loc,...data});
          localStorage.setItem("location", JSON.stringify(data));
        });
      }
    } catch (err) {
      console.error("Unable to get location state -- something has gone wrong", err);

      GetGeoLocation(data.site.siteMetadata.apiURL).then(data => {
        setLoc({...loc,...data});
        localStorage.setItem("location", JSON.stringify(data));
      });
    }

    console.info("Location check completed...");
  }

  function getSetUserInfo() {
    var uInfo = localStorage.getItem("uInfo");
    
    if (uInfo) {
      setUInfo(JSON.parse(uInfo));
    }
  }

  function getSetSearchInfo() {
    var sData = sessionStorage.getItem("sData");
    
    if (sData) {
      setSData(JSON.parse(sData))
    }
  }

  // Run once on load
  useEffect(() => {
    getSetLocationState()
    getSetUserInfo()
    getSetSearchInfo()
  }, [])
}

export default StateManager