import { atom } from 'recoil';

export enum EnumShownSearchLimit {
    Small=25,
    Medium=35,
    Max=50
}

export const locationInformation = atom({
    key: 'locationInfo',
    default: {
        origin_city:"",
        origin_state_shortTxt:"",
        origin_state_longTxt:"",
        origin_county:"",
        origin_country:"",
        origin_postalCode:"",
        origin_preferredPostalCode: "", // to be used for used for 'user override' on their location setting.
    }
});

export const userInformation = atom({
    key: 'userInformation',
    default: {
        setByService: false,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        id: ""
    }
})


export const searchData = atom({
    key: 'searchCriteria',
    default: {
        lastSearchFilters: {
            pt: null,
            man: null,
            kw: null,
            zip: null,
            r: null,
            miw: null,
            maw: null,
            mil: null,
            mal: null,
            mis: null,
            mas: null,
            mip: null,
            map: null,
            miso: null,
            maso: null,
            miy: null,
            may: null
        },
        lastSearchPresentationMode: 1,  // Enum Row
        lastSearchPresentPerPage: EnumShownSearchLimit.Small,
        lastSearchPageNumb: 1,
        lastSearchSort: 'close'
    }
})

export const lastSearchResults = atom({
    key: 'lastSearchResults',
    default : {
        lastSearchFiltersUsed: {},
        lastSearchTotalProdFound: 0,
        lastSearchJSONInventory: [],
        lastSearchPresentPerPage: EnumShownSearchLimit.Small
    }
})