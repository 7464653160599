/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { RecoilRoot } from 'recoil';
import StateManager from './src/common/stateManager'
import 'semantic-ui-css/semantic.min.css'
import './src/common.css'
import 'react-multi-carousel/lib/styles.css';
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

export const wrapRootElement = ({ element }) => {
  return (
    <RecoilRoot>
      <StateManager />
      {element}
    </RecoilRoot> 
  )
}