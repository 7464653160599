import React from 'react';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import PACKAGES from '../data/config_RVPackages.json';
import { window, document, exists } from 'browser-monads';
import ClientJS from 'clientjs';
import { useRecoilState } from 'recoil';
import { locationInformation } from '../atoms/atoms';
    
// Using this as a possible cache bust when needed, and to track for cookie/analytics purposes
// to see when people are coming on-to and off-of a version of the software.
export var BUILD_DATE = null;
export var COMING_SOON = null;
export var API_URL = null;

export function mobileIsPortrait() {
    var orientation = ((window.innerWidth / window.innerHeight) > 1 ? true : false);
    return orientation
}

export function GetClientFingerprint() {
    // Create a new ClientJS object
    if (typeof window !== undefined) {
        const client = new ClientJS()
        const windowClient = new window.ClientJS();

        const fingerPrint = windowClient.getFingerprint();

        return fingerPrint;
    }
}

export function GetAffiliateMention(alignment?) {
    return (
        <div style={{textAlign:alignment ? alignment : "center", fontStyle:"italic", margin:"2em 0"}}>By purchasing from our affilaite programs, we may get a small commission</div>
    )
}

export function buildAmazonImage(ASIN, quality) {
    const QUAL = {
        low: 120,
        med: 250,
        high: 600
    }

    var qVal = QUAL[quality] || 250;

    return `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${ASIN}&Format=_SL${qVal}_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rvsnag-20&language=en_US`
}


function data() {
    const data = useStaticQuery(graphql`
    query {
      comingSoon: file(relativePath: {eq: "comingSoon.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        buildTime(formatString: "YYYY-MM-DD hh:mm a z")
        siteMetadata {
          apiURL
        }
      }
    }
  `)
    // console.log("MORE DATA", query.site.buildTime)

    return data;
}


export async function GetGeoLocation(useThisAPIPath) {
    console.log("Using this API Path .... => ", useThisAPIPath)
    return axios.get(`${useThisAPIPath}/.netlify/functions/locate`).then(res => {
        return res.data;
    }).catch(error => console.error("MyErrors -> ", error));
}


// This utility function should help get help provide the correct/desired
// zipcode in the various API calls and form fields.
// The order is this:  
// - if recently put into form field, use this value first (session) - annotate as "user_set_pref"
// - in the absence of step 1, geoLoc data SHOULD be available in the session data, if it's set there, then use that. 
// - if geoData is NOT available, regardless of which step, we should call to get it - since it's relatd to all sorts of other
//   requests/data mappings. 
// - We should have a distincton between "userLastSearch" / user_set_pref / geoLoc.ip->lat/long 
export function GetPreferredSearchZip() {
    const [loc, setLoc] = useRecoilState(locationInformation);
    
    // if (!loc.origin_postalCode) {
    //     GetGeoLocation();
    // }

    var preferredZip = null;

    if (loc.lastUsedZip != "" && loc.lastUsedZip != undefined) {
        preferredZip = loc.lastUsedZip;
    } else if (loc.origin_preferredPostalCode) {
        preferredZip = loc.origin_preferredPostalCode;
    } else if (window.sessionStorage.getItem('sData')) {
        try {
            preferredZip = JSON.parse(window.sessionStorage.getItem('sData')).lastSearchZip;

            if (preferredZip == "") {
                preferredZip = loc.origin_postalCode;
            }

        } catch {
            preferredZip = loc.origin_postalCode;
        }
    } else if (loc.origin_postalCode) {
        preferredZip = loc.origin_postalCode;
    }

    // Set the preferred zip to localStorage for later use...
    // PS = _P_referred _S_earch Zip
    localStorage.setItem("psZip", preferredZip.split('-'[0]));
    
    // Set the new location to the atom so it propogates.
    var newLoc = {...loc};
    newLoc.lastUsedZip = preferredZip;

    return newLoc
}

export const setQueryData = () => {
    COMING_SOON = data().comingSoon;
    BUILD_DATE = data().site.buildTime;
}

export function ContructImgURL(url:string, width:string, height:string, fillMode:string, format:string, quality:string, midURL:string, imgPath:string, defaultImg:boolean) {    
    setQueryData();

    try {
        if (defaultImg) {
            return COMING_SOON.childImageSharp.fluid; 
        } else {
            return `${url}${width},${height},${fillMode},${format},${quality}/${midURL}${imgPath}` 
        }
    } catch {
        return COMING_SOON.publicURL;  
    }
}


export const PRICES = {
    BASIC: PACKAGES.packages.basic.price,
    BEST: PACKAGES.packages.bestValue.price,
    PREMIUM: PACKAGES.packages.premium.price
}

export function toTitleCase(titleString) {
    if (!titleString) {
        titleString = "None"
    }

    return titleString.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const formatToUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});


export function GetBuildDate() {
    return BUILD_DATE ? BUILD_DATE : ""
}


export function GetAPIURL() {
    return API_URL
}


export function CalcDate(originalDate) {
    const DAY_CALC_TIME = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const TODAYS_DATE = new Date();
    const originPostingDate = new Date(originalDate);
    const diffDays = Math.round(Math.abs((TODAYS_DATE - originPostingDate) / DAY_CALC_TIME));
    return diffDays
}

export function normalizeLink(link) {
    // Normalize the path...
    if (!link) {
      return
    }
    
    let newPath = `${link.toLowerCase()}`;
    // Replace whitespaces with dashes
    newPath = newPath.replace(/ /g, "-");
    // Remove '
    newPath = newPath.replace(/'/g, '');
    // Remove ,
    newPath = newPath.replace(/,/g, '');

    return newPath
}
